import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const HotRolledStructuralSteelPage = () => {
  return (
    <Layout hero={<HeroImage title="Hot Rolled Structural Steel" />} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center mt-4">
          <Col sm={10}>
            <Row className="mb-4">
              <Col sm={12}>
                <p>
                  Orrcon Steel offers a complete range of hot rolled structural products including 
                  beams, channel, angle, merchant bar (flats, angles, rounds, squares) and plate & steel sheets.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row single">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/hot-rolled-structural-steel">
                    <h2 className="title">Hot Rolled Structural Steel</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="linked-list info">
                      <Link to="/product-info/hot-rolled-structural-steel" className="mb-2">
                        Structural Angle
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/structural-angle/equal-angle" className="mb-2">
                            Equal Angle
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/structural-angle/unequel-angle" className="mb-2">
                            Unequal Angle
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="linked-list info">
                      <Link to="/product-info/hot-rolled-structural-steel" className="mb-2">
                        Merchant Bar
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/merchant-bar/flats" className="mb-2">
                            Flats
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/merchant-bar/rounds" className="mb-2">
                            Rounds
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/merchant-bar/square" className="mb-2">
                            Square
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="linked-list info">
                      <Link to="/product-info/hot-rolled-structural-steel" className="mb-2">
                        Plate & Sheet
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/plate-sheet/steel-floor-checker" className="mb-2">
                            Steel Floor (Checker)
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/plate-sheet/steel-plate" className="mb-2">
                            Steel Plate
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/plate-sheet/steel-sheet" className="mb-2">
                            Steel Sheet
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/hot-rolled-structural-steel/beam" className="mb-2">
                        Beam
                      </Link>
                      <p>Universal Beams Available.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/hot-rolled-structural-steel/channel" className="mb-2">
                        Channel
                      </Link>
                      <p>Manufactured to Australian Standards.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/hot-rolled-structural-steel/column" className="mb-2">
                        Column
                      </Link>
                      <p>Universal Columns Available.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default HotRolledStructuralSteelPage

export const Head = () => <title>Hot Rolled Structural Steel</title>
